import React from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import PageContainer from "../../../components/PageContainer";
import ContentRow from "./ContentRow";
import ParallaxImage from "./ParallaxImage";

function Information() {
  const { t, language } = useTranslation();

  return (
    <Box
      className="regulation-container"
      position="relative"
      paddingY="3.5xl"
      minHeight="viewContactH"
      backgroundColor="light.400"
      overflow="hidden"
    >
      <PageContainer>
        <ContentRow text={t("page@regulation@info@text-1")} />
        <ContentRow text={t("page@regulation@info@text-2")} />
        <ContentRow text={t("page@regulation@info@text-3")} />
        <ContentRow text={t("page@regulation@info@text-4")} />
        <ContentRow text={t("page@regulation@info@text-5")} />
        <ContentRow
          text={t("page@regulation@info@text-6")}
          link={t("page@regulation@info@text-6-link")}
        />
        <ContentRow
          text={t("page@regulation@info@text-7")}
          link={t("page@regulation@info@text-7-link")}
        />
        <ContentRow
          text={t("page@regulation@info@text-8")}
          link={t("page@regulation@info@text-8-link")}
        />

        {"hy-AM" !== language && "en-US" !== language && (
          <>
            <ContentRow text={t("page@regulation@info@text-9")} />
          </>
        )}

        <ContentRow
          text={t("page@regulation@info@text-10")}
          link={t("page@regulation@info@text-10-link")}
          linkTitle={t("page@regulation@info@text-10-link-title")}
        />
      </PageContainer>

      <ParallaxImage
        y={["240%", "-50%"]}
        size={190}
        rotate={-17}
        placement="left"
      />
      <ParallaxImage
        y={["-40%", "350%"]}
        size={90}
        rotate={52}
        placement="right"
      />
    </Box>
  );
}

export default Information;
