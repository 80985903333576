import React, { useMemo } from "react";
import { Box, Img } from "@chakra-ui/react";
import PageParallax from "../../../components/PageParallax";
import imgLogo from "../../../images/investment-icon.svg";

function ParallaxImage({ y, placement, size, rotate }) {
  const moreProps = useMemo(
    () => ({
      top: "50px",
      [placement]: `calc(11% - ${size / 2}px)`,
    }),
    [placement, size]
  );

  return (
    <Box opacity={0.2} position="absolute" {...moreProps}>
      <PageParallax y={y}>
        <Img
          draggable={false}
          src={imgLogo}
          filter="grayscale(0.9)"
          opacity="0.5"
          maxWidth={`${size}px`}
          transform={`rotate(${rotate}deg)`}
        />
      </PageParallax>
    </Box>
  );
}

ParallaxImage.defaultProps = {
  size: 50,
};

export default ParallaxImage;
