import React from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import animation from "./_animation";

function ContentRow({ text, link, linkTitle }) {
  return (
    <ViewportAnimation {...animation}>
      <Box marginBottom="2xl">
        <Text
          // textAlign="justify"
          fontSize="md"
        >
          {text}

          {link && (
            <Box display="inline">
              {" "}
              <Link
                href={link}
                target="_blank"
                color="blue.600"
                _hover={{ color: "blue.1100" }}
              >
                {linkTitle || link}
              </Link>
            </Box>
          )}
        </Text>
      </Box>
    </ViewportAnimation>
  );
}

export default ContentRow;
