import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";

import Hero from "../views/Regulation/Hero";
import Information from "../views/Regulation/Information";

function Regulation() {
  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <Information />
      </AnimatedContent>
    </Box>
  );
}

export default Regulation;
